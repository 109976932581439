import React, { useEffect, useState } from 'react'
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  CardMedia,
  Grid,
  Typography,
  Menu,
  CircularProgress,
  MenuItem,
  Avatar,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import { LogoIcon, MenuIcon, NotificationIcon } from '../../assets/icons';
import Colors from '../../style';
import Images from '../../assets/images';
import AdminServices from '../../api/admin/AdminServices';
import { useLocation } from 'react-router-dom';
import { onMessageListener } from '../../../firebase';
import { ErrorToaster } from '../../components/toaster';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import { LockReset, VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import { InputSelectField } from '../../components/inputfields';
import { useForm } from 'react-hook-form';
import { PrimaryButton, SecondaryButton } from '../../components/buttons';

function PasswordDialog({ open, handleClose }) {
  const [visibleOldPassword, setVisibleOldPassword] = useState(false);
  const [visiblePassword, setVisiblePassword] = useState(false);
  const [visibleConfirmPassword, setVisibleConfirmPassword] = useState(false);
  const { register, handleSubmit, formState: { errors }, reset, getValues, watch, trigger } = useForm();

  watch("password");
  watch("oldpassword");

  return (
    <Dialog
      component={"form"}
      onSubmit={handleSubmit()}
      open={open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={"xs"}
      slotProps={{
        backdrop: {
          sx: {
            backdropFilter: "blur(8px)",
            backgroundColor: "transparent",
          }
        }
      }}
      PaperProps={{
        sx: {
          border: `4px solid ${Colors.dark2}`,
          borderRadius: "50px",
          background: Colors.dark1,
          color: Colors.white,
          p: 2,
        },
      }}
      sx={{
        ".MuiDialogContent-root": {
          pt: "8px !important",
          pb: "0px important"
        }
      }}
    >
      <DialogTitle>Change Password</DialogTitle>
      <DialogContent>
        <Grid container rowGap={2}>
          <Grid item md={12}>
            <InputSelectField
              fullWidth={true}
              label={"Old Password"}
              type={visibleOldPassword ? "text" : "password"}
              register={register("oldpassword", {
                required: "Old Password"
              })}
              error={!!errors?.oldpassword}
              helperText={errors?.oldpassword?.message}
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={() => setVisibleOldPassword(!visiblePassword)}
                  >
                    {visibleOldPassword ? <VisibilityOutlined sx={{ color: Colors.primary }} />
                      : <VisibilityOffOutlined sx={{ color: Colors.primary }} />}
                  </IconButton>
                )
              }}
            />
          </Grid>
          <Grid item md={12}>
            <InputSelectField
              fullWidth={true}
              label={"Password"}
              type={visiblePassword ? "text" : "password"}
              register={register("password", {
                required: "New Password",
                validate: (value) => {
                  const oldPassword = getValues("oldpassword");
                  return value != oldPassword || "New passsword cannot be same as old password";
                },
                onChange: () => {
                  trigger("password"); // Re-validate confirm password field on change
                }
              })}
              error={!!errors?.password}
              helperText={errors?.password?.message}
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={() => setVisiblePassword(!visiblePassword)}
                  >
                    {visiblePassword ? <VisibilityOutlined sx={{ color: Colors.primary }} />
                      : <VisibilityOffOutlined sx={{ color: Colors.primary }} />}
                  </IconButton>
                )
              }}
            />
          </Grid>
          <Grid item md={12}>
            <InputSelectField
              fullWidth={true}
              label={"Confirm Password"}
              type={visibleConfirmPassword ? "text" : "password"}
              register={register("cnfpassword", {
                required: "Confirm Password",
                validate: (value) => {
                  const password = getValues("password");
                  return value === password || "Passwords do not match";
                },
                onChange: () => {
                  trigger("cnfpassword"); // Re-validate confirm password field on change
                }
              })}
              error={!!errors?.cnfpassword}
              helperText={errors?.cnfpassword?.message}
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={() => setVisibleConfirmPassword(!visibleConfirmPassword)}
                  >
                    {visibleConfirmPassword ? <VisibilityOutlined sx={{ color: Colors.primary }} />
                      : <VisibilityOffOutlined sx={{ color: Colors.primary }} />}
                  </IconButton>
                )
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <PrimaryButton
          type={"submit"}
          title={"Submit"}
        />
        <SecondaryButton
          title={"Cancel"}
          onClick={() => handleClose()}
        />
      </DialogActions>
    </Dialog>
  )
}

function Header({ onClick }) {
  const [loading, setLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [notificationsCount, setNotificationsCount] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [accAnchorEl, setAccAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const open = Boolean(anchorEl);
  const openAcc = Boolean(accAnchorEl);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { mail, role } = useAuth();

  onMessageListener()
    .then((payload) => {
      getNotifications();
      getNotificationsCount();
    })
    .catch((err) => console.log("failed: ", err));

  const getNotifications = async () => {
    try {
      const result = await AdminServices.getNotifications();
      setNotifications(result?.data?.notifications)
    } catch (error) {
      ErrorToaster(error);
    }
  };

  const getNotificationsCount = async () => {
    try {
      const result = await AdminServices.getNotificationsCount();
      setNotificationsCount(result?.data?.count);
    } catch (err) {
      console.log(err)
    }
  };

  const handleNavigate = async (data) => {
    if (data.pagename == "memberProfile") {
      try {
        const result = await AdminServices.getMemberById(data.id);
        if (result.responseCode == 200) {
          navigate(`/member-detail/${data.id}`, {
            state: {
              navigateFrom: ""
            }
          });
        }
      } catch (err) {
        console.log(err)
      }
    } else if (data.pagename == "BusinessProfile") {
      try {
        const result = await AdminServices.getBusinessById(data.id);
        if (result.responseCode == 200) {
          navigate(`/business-detail/${data.id}`, {
            state: {
              navigateFrom: "",
            }
          });
        }
      } catch (err) {
        console.log(err)
      }
    } else if (data.pagename == "offer") {
      try {
        const result = await AdminServices.getOfferDetails(data.id);
        if (result.responseCode == 200) {
          navigate(`/offer-detail/${data.id}`, {
            state: {
              navigateFrom: "",
            }
          });
        }
      } catch (err) {
        console.log(err)
      }
    } else if (data.pagename == "event") {
      try {
        const result = await AdminServices.getEventDetails(data.id);
        if (result.responseCode == 200) {
          navigate(`/event-detail/${data.id}`, {
            state: {
              navigateFrom: "",
            }
          });
        }
      } catch (err) {
        console.log(err)
      }
    }
  }

  useEffect(() => {
    getNotifications();
    getNotificationsCount();
  }, [pathname]);

  return (
    <AppBar
      position="fixed"
      sx={{
        boxShadow: "none",
        zIndex: 1300,
        background: Colors.dark1
      }}
    >
      <PasswordDialog
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
      />
      <Toolbar
        sx={{
          py: "12px",
          gap: "16px"
        }}
      >
        <Box
          sx={{
            width: "266px",
            display: "flex",
          }}
        >
          <Box
            sx={{
              width: "193px",
              px: 3
            }}
          >
            <CardMedia
              component={"img"}
              src={Images.logo}
              sx={{
                width: "100%",
                objectFit: "contain"
              }}
            />
          </Box>
        </Box>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
          onClick={onClick}
        >
          <MenuIcon />
        </IconButton>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container justifyContent={"flex-end"}>
            <Grid item md={5}>
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "flex-end"
                }}
              >
                <IconButton
                  // sx={{ mr: 3 }}
                  onClick={(e) => setAnchorEl(e.currentTarget)}
                >
                  <NotificationIcon />
                </IconButton>
                <Menu
                  open={open}
                  anchorEl={anchorEl}
                  onClose={() => setAnchorEl(false)}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  slotProps={{
                    paper: {
                      sx: {
                        width: "300px",
                        height: "70%",
                        overflow: "auto",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        background: Colors.dark3,
                        borderRadius: "12px",
                        border: `1px solid ${Colors.secondary}`,
                        "&::-webkit-scrollbar": {
                          display: "none",
                        },
                        "-ms-overflow-style": "none", /* IE and Edge */
                        "scrollbar-width": "none", /* Firefox */
                      },
                    },
                  }}
                  MenuListProps={{
                    sx: {
                      p: 0
                    }
                  }}
                >
                  <Box>
                    {loading ? (
                      <CircularProgress />
                    ) : notifications?.length > 0 ? (
                      notifications.map((item, index) => {
                        const data = JSON.parse(item.data);
                        return (
                          <MenuItem
                            key={item._id}
                            onClick={() => {
                              handleNavigate(data);
                              setAnchorEl(null);
                            }}
                            sx={{
                              borderRadius: 0,
                              borderBottom: `1px solid ${Colors.dark2}`,
                              bgcolor:
                                index >= notificationsCount
                                  ? "transparent"
                                  : Colors.dark3,
                              py: 2,
                              gap: "10px",
                              alignItems: "start",
                              ":hover": {
                                background: Colors.buttonGradient,
                                ".changeColor": {
                                  color: Colors.dark1,
                                  fontWeight: 600,
                                },
                                "span": {
                                  color: Colors.dark1
                                },
                              },
                            }}
                          >
                            <LogoIcon />
                            <Box sx={{ whiteSpace: "break-spaces" }}>
                              <Typography
                                className="changeColor"
                                variant="body2"
                                sx={{ color: Colors.white }}
                              >
                                {item.title}
                              </Typography>
                              {item.createdAt && (
                                <Typography
                                  variant="caption"
                                  sx={{ color: Colors.secondary }}
                                >
                                  {moment(item?.createdAt).format("DD/MM/YYYY, hh:mm A")}
                                </Typography>
                              )}
                            </Box>
                          </MenuItem>
                        )
                      })
                    ) : (
                      <Typography
                        variant="body1"
                        sx={{ py: 2, px: 1, textAlign: "center" }}
                      >
                        Your have no notification.
                      </Typography>
                    )}
                  </Box>
                </Menu>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px"
                  }}
                >
                  <IconButton
                    onClick={(e) => setAccAnchorEl(e.currentTarget)}
                  >
                    <LogoIcon />
                  </IconButton>
                  <Typography>Admin</Typography>
                </Box>
                <Menu
                  open={openAcc}
                  anchorEl={accAnchorEl}
                  onClose={() => setAccAnchorEl(false)}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  slotProps={{
                    paper: {
                      sx: {
                        width: "350px",
                        overflow: "visible",
                        background: Colors.dark3,
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        // mt: 1.5,
                        border: `1px solid ${Colors.secondary}`,
                        borderRadius: "12px",
                        "& .MuiAvatar-root": { mx: 1 },
                        // "&:before": {
                        //   content: '""',
                        //   display: "block",
                        //   position: "absolute",
                        //   top: 0,
                        //   right: 24,
                        //   width: 10,
                        //   height: 10,
                        //   bgcolor: Colors.dark3,
                        //   transform: "translateY(-50%) rotate(45deg)",
                        //   zIndex: 0,
                        // },
                      },
                    },
                  }}
                  MenuListProps={{
                    sx: {
                      p: 0
                    }
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: 500,
                      color: Colors.white,
                      px: 2,
                      py: 1.5
                    }}
                  >
                    User Profile
                  </Typography>

                  <MenuItem
                    sx={{
                      borderRadius: 0,
                      bgcolor: Colors.dark3,
                      borderBottom: `1px solid ${Colors.dark1}`,
                      py: 2,
                      gap: "10px",
                      alignItems: "start",
                      ":hover": {
                        cursor: "default",
                        background: "transparent"
                      }
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1
                      }}
                    >
                      {/* <Avatar
                        alt="Admin"
                        src="/static/images/avatar/1.jpg"
                        sx={{ width: 48, height: 48 }}
                      /> */}
                      <Box
                        sx={{ width: 40, height: 40 }}
                      >
                        <CardMedia
                          component={"img"}
                          src={Images.defaultUser}
                          sx={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover"
                          }}
                        />
                      </Box>
                      <Box>
                        <Typography
                          className='changeColor'
                          sx={{ color: Colors.primary, fontWeight: 600 }}>
                          Admin
                        </Typography>
                        <Typography
                          variant="caption"
                          sx={{ color: Colors.white }}
                        >
                          {mail}
                        </Typography>
                      </Box>
                    </Box>
                  </MenuItem>
                  <MenuItem
                    sx={{
                      borderRadius: 0,
                      bgcolor: Colors.dark3,
                      py: 2,
                      gap: "10px",
                      alignItems: "start",
                      borderRadius: "0px 0px 12px 12px",
                      ":hover": {
                        background: Colors.buttonGradient,
                        ".changeColor": {
                          color: Colors.white,
                        },
                        "span": {
                          color: Colors.dark1
                        },
                      },
                    }}
                    onClick={() => setOpenDialog(true)}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1.5
                      }}
                    >
                      <LockReset sx={{ color: Colors.white, fontSize: "36px" }} />
                      <Typography
                        variant="body2"
                        sx={{ color: Colors.white }}
                      >
                        Change Password
                      </Typography>
                    </Box>
                  </MenuItem>
                </Menu>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Toolbar>
    </AppBar>
  )
}

export default Header;