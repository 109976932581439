import { Box, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from "@mui/material";
import Colors from "../../style";
import { InputSelectField } from "../inputfields";
import { PrimaryButton, SecondaryButton } from "../buttons";
import { Close, CloseRounded } from "@mui/icons-material";
import { useForm } from 'react-hook-form'
import { useEffect, useState } from "react";

const categoryTypes = ["Member", "Business", "Events & Offers"];

export default function CategoryDialog({
  title, open, onClose, onSubmit, data, loading, selectedType, setSelectedType
}) {
  const { register, handleSubmit, formState: { errors }, setValue, reset } = useForm();

  const defaultData = () => {
    setSelectedType(data?.type)
    setValue("type", data?.type)
    setValue("name", data?.name);
    setValue("name_ar", data?.name_ar);
  }

  useEffect(() => {
    if (data) {
      defaultData();
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth={true}
      maxWidth={"sm"}
      slotProps={{
        backdrop: {
          sx: {
            backdropFilter: "blur(8px)",
            backgroundColor: "transparent",
          }
        }
      }}
      PaperProps={{
        sx: {
          border: `4px solid ${Colors.dark2}`,
          borderRadius: "50px",
          background: Colors.dark1,
          color: Colors.white,
          p: 3
        },
      }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent sx={{ pt: `8px !important` }}>
        <Grid
          container
          spacing={2}
          component={"form"}
          onSubmit={handleSubmit((data) => onSubmit(data, reset))}
        >
          <Grid item md={12}>
            <InputSelectField
              value={selectedType}
              select={true}
              type={"text"}
              variant={"outlined"}
              fullWidth={true}
              label={"Select Type"}
              data={categoryTypes}
              register={register("type", {
                required: selectedType == "" ? "Please select a type" : false,
                onChange: (e) => {
                  setSelectedType(e.target.value);
                  setValue("type", e.target.value);
                }
              })}
              error={errors?.type && true}
              helperText={errors?.type?.message}
            />
          </Grid>
          <Grid item md={12}>
            <InputSelectField
              type={"text"}
              variant={"outlined"}
              fullWidth={true}
              label={selectedType == "Events & Offers" ? "Type Name" : "Category Name"}
              register={register("name", {
                required: "Please enter a category name"
              })}
              error={errors?.name && true}
              helperText={errors?.name?.message}
            />
          </Grid>
          <Grid item md={12}>
            <InputSelectField
              dir={"rtl"}
              type={"text"}
              variant={"outlined"}
              fullWidth={true}
              label={selectedType == "Events & Offers" ? "اسم النوع" : "اسم الفئة"}
              register={register("name_ar", {
                required: "Please enter a category name"
              })}
              error={errors?.name_ar && true}
              helperText={errors?.name_ar?.message}
            />
          </Grid>
          <Grid item md={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center"
              }}
            >
              <PrimaryButton
                loading={loading}
                type={"submit"}
                title={title == "Create Category" ? "Create" : "Update"}
              />
            </Box>
          </Grid>
        </Grid>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            top: "30px",
            right: "30px",
            width: "30px",
            height: "30px",
            background: Colors.secondary,
            borderRadius: "50%",
          }}
        >
          <IconButton onClick={onClose}>
            <CloseRounded sx={{ color: Colors.dark1 }} />
          </IconButton>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export const ConfirmationDialog = ({
  open, onClose, onClick, loading, title, desc, prmBtnTitle, secBtnTitle, secOnClick, secLoading
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth={true}
      maxWidth={"xs"}
      slotProps={{
        backdrop: {
          sx: {
            backdropFilter: "blur(8px)",
            backgroundColor: "transparent",
          }
        }
      }}
      PaperProps={{
        sx: {
          maxWidth: "320px",
          border: `4px solid ${Colors.dark2}`,
          borderRadius: "50px",
          background: Colors.dark1,
          color: Colors.white,
          p: 3
        },
      }}
    >
      <DialogTitle sx={{ textAlign: "center", position: "relative" }}>{title} ?
        <IconButton
          sx={{
            background: Colors.secondary,
            width: "24px",
            height: "24px",
            position: "absolute",
            top: 0,
            right: 1,
            ":hover": {
              background: Colors.secondary + 40
            }
          }}
          onClick={() => onClose()}
        >
          <Close sx={{ color: Colors.white, fontSize: "18px" }} />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ pt: `8px !important` }}>
        <Grid
          container
          spacing={2}
        >
          <Grid item md={12}>
            <Typography sx={{ textAlign: "center" }}>
              {desc}
            </Typography>
          </Grid>
          <Grid item md={12}>
            <Grid container gap={2} justifyContent={"center"}>
              <Grid item md={4}>
                <PrimaryButton
                  fullWidth
                  loading={loading}
                  title={prmBtnTitle}
                  onClick={onClick}
                />
              </Grid>
              <Grid item md={4}>
                <SecondaryButton
                  fullWidth
                  loading={secLoading}
                  title={secBtnTitle}
                  onClick={secOnClick}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export const EditDialog = ({ open, onClose, title, data }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth={true}
      maxWidth={"md"}
      slotProps={{
        backdrop: {
          sx: {
            backdropFilter: "blur(8px)",
            backgroundColor: "transparent",
          }
        }
      }}
      PaperProps={{
        sx: {
          border: `4px solid ${Colors.dark2}`,
          borderRadius: "50px",
          background: Colors.dark1,
          color: Colors.white,
          p: 3
        },
      }}
    >
      <DialogTitle sx={{ textAlign: "center" }}>{title}</DialogTitle>
      <DialogContent sx={{ pt: `8px !important` }}>
        {data}
      </DialogContent>
    </Dialog>
  )
}