import React, { Fragment, useState, useEffect, useRef } from 'react'
import { Box, CardMedia, Grid, IconButton, Tooltip, Typography, Modal, Dialog, DialogTitle, DialogContentText, DialogContent } from '@mui/material';
import BreadCrumb from '../../../components/breadcrumb';
import Colors from '../../../style';
import { InfoIcon, InstagramIcon, SnapchatIcon, TiktokIcon, UserIcon } from '../../../assets/icons';
import { Close, CloudUpload, Edit, SaveAlt } from '@mui/icons-material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import AdminServices from '../../../api/admin/AdminServices';
import { ErrorToaster, SuccessToaster } from '../../../components/toaster';
import { SecondaryCard } from '../../../components/cards';
import { PageDot } from '../../../components/UI';
import { baseUrl } from '../../../../axios';
import { EditButton, PrimaryButton, SecondaryButton } from '../../../components/buttons';
import { EditField } from '../../../components/inputfields';
import { useForm } from 'react-hook-form';

const ImageModal = ({ open, handleClose, src }) => {
  const downloadScreeshot = async () => {
    try {
      window.location.href = `${baseUrl}/api/download-media?path=${src?.membershipRequest?.screenshot}&name=review.png`
    } catch (error) {
      console.error(error);
    }
  }
  return (
    <Dialog
      maxWidth="xs"
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      slotProps={{
        backdrop: {
          sx: {
            backdropFilter: "blur(8px)",
            backgroundColor: "transparent",
          }
        }
      }}
      PaperProps={{
        sx: {
          border: `4px solid ${Colors.dark2}`,
          borderRadius: "50px",
          background: Colors.dark1,
          color: Colors.white,
          p: 3
        },
      }}
    >
      <DialogTitle sx={{ textAlign: "center" }} id="alert-dialog-title">
        Payment Proof
      </DialogTitle>
      <DialogContent style={{ position: "relative" }}>
        <CardMedia
          component={"img"}
          src={baseUrl + src?.membershipRequest?.screenshot}
          sx={{
            width: "100%",
            height: "250px",
            objectFit: "conver",
            borderRadius: "20px"
          }}
        />
        <IconButton
          sx={{
            position: "absolute",
            top: "4px",
            right: "28px",
            background: Colors.buttonGradient,
            ":hover": {
              background: Colors.buttonGradient
            }
          }}
          onClick={() => downloadScreeshot()}
        >
          <SaveAlt sx={{ color: Colors.dark1 }} />
        </IconButton>
      </DialogContent>
      <DialogContentText style={{ paddingInline: "24px" }} id="alert-dialog-description">
        <Typography sx={{ fontFamily: "Nunito Sans", fontSize: "20px", color: Colors.white }}>
          Notes:
        </Typography>
        <Typography sx={{ fontFamily: "Nunito Sans", fontSize: "16px", color: Colors.white }}>
          {src?.membershipRequest?.notes}
        </Typography>
      </DialogContentText>
      {/* <Grid container>
        <Grid item md={4}> */}
      {/* <ReactImageMagnify
        {...{
          smallImage: {
            alt: "screenshot",
            isFluidWidth: true,
            // src: baseUrl + "/docs/files/products/" + productDetails.pic,
            src: baseUrl + src,
          },
          largeImage: {
            src: baseUrl + src,
            // src: src,
            width: 1200,
            height: 1000,
          },
          enlargedImageContainerStyle: { zIndex: 9 },
          enlargedImageContainerDimensions: {
            width: "100%",
            height: "100%",
          },
          enlargedImagePosition: "over",
        }}
      /> */}

    </Dialog>
  )
}

function BusinessDetail() {
  const [data, setData] = useState(null);
  const [membershipData, setMembershipData] = useState(null);
  const [open, setOpen] = useState(false);
  const [src, setSrc] = useState("");
  const [isActive, setIsActive] = useState(null);
  const [isMounted, setIsMounted] = useState(true);
  const [brands, setBrands] = useState(null);
  const [approveLoading, setApproveLoading] = useState(false);
  const [declineLoading, setDeclineLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [updatedImage, setUpdatedImage] = useState(null);
  const { register, handleSubmit, setValue } = useForm();
  const inputRef = useRef();
  const { id } = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();

  const handleStatusApproved = async () => {
    try {
      const obj = {
        user_id: data?.user_id,
        is_approved: true,
      };
      const result = await AdminServices.updateStatus(obj);
      if (result.responseCode == 200) {
        SuccessToaster(result?.message);
      }
    } catch (e) {
      console.log(e);
    } finally {
      window.location.reload();
    };
  };

  const handleStatusDisapproved = async () => {
    try {
      const obj = {
        user_id: data?.user_id,
        is_approved: false,
      };
      const result = await AdminServices.updateStatus(obj);
      if (result.responseCode == 200) {
        SuccessToaster(result?.message);
      }
    } catch (e) {
      console.log(e);
    } finally {
      window.location.reload();
    };
  };

  const handleStatus = async (e) => {
    const obj = {
      is_active: e.target.checked,
    }
    try {
      const result = await AdminServices.updateUser(data?.user_id, obj);
      if (result.responseCode == 200) {
        SuccessToaster(result?.message)
        setIsActive(result.data.updatedUser.is_active)
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchData = async () => {
    try {
      const result = await AdminServices.getBusinessById(id);
      if (result.responseCode === 200) {
        setData(result?.data?.businesses[0]);
        setIsActive(result?.data?.businesses[0].is_active);
      }
      const brandsData = await AdminServices.getAllBrands(id)
      if (brandsData.responseCode == 200) {
        setBrands(brandsData?.data?.brands);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getMembershipDetails = async () => {
    try {
      const result = await AdminServices.getMembershipDetails(id);
      if (result.responseCode == 200) {
        setMembershipData(result.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const approveMembership = async () => {
    setApproveLoading(true);
    const obj = {
      requestId: membershipData?.membershipRequest?._id,
      status: "approved"
    }
    try {
      const result = await AdminServices.approveSubscription(obj);
      if (result.responseCode == 200) {
        const { message, responseCode } = await AdminServices.startSubscription(membershipData?.membershipRequest?.user_id);
        if (responseCode == 200) {
          SuccessToaster(message);
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setApproveLoading(false);
    }
  };

  const declineMembership = async () => {
    setDeclineLoading(true);
    const obj = {
      requestId: membershipData?.membershipRequest?._id,
      status: "rejected"
    }
    try {
      const result = await AdminServices.approveSubscription(obj);
      if (result.responseCode == 200) {
        SuccessToaster(result?.message);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setDeclineLoading(false);
    }
  };

  const handleClick = () => {
    setIsEdit(!isEdit);
  };

  const handleFileChange = async (file) => {
    const formData = new FormData();
    formData.append("document", file);
    try {
      const result = await AdminServices.uploadImage(formData);
      if (result.responseCode == 200) {
        SuccessToaster(result.message);
        setUpdatedImage(result?.data?.files[0]?.path);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const setDefaultData = () => {
    setValue("name", data?.company_name);
    setUpdatedImage(data?.company_logo);
  };

  const updateData = async (formData) => {
    const obj = {
      businessProfile: {
        company_name: formData.name,
        company_logo: updatedImage,
      }
    }
    try {
      const result = await AdminServices.updateMember(id, obj);
      if (result.responseCode == 200) {
        SuccessToaster(result.message);
        setIsEdit(false);
      }
    } catch (err) {
      ErrorToaster(err);
    } finally {
      fetchData();
    }
  }

  useEffect(() => {
    // let isMounted = true;
    fetchData();
    getMembershipDetails();

    return () => {
      setIsMounted(false);
    };
  }, [id]);

  useEffect(() => {
    setDefaultData();
  }, [data])

  return (data != null && (
    <Fragment>
      <ImageModal open={open} handleClose={() => setOpen(false)} src={src} />
      <Grid container alignItems={"center"}>
        <Grid item md={12}
          sx={{
            borderBottom: `1px solid ${Colors.dark2}`
          }}
        >
          <BreadCrumb
            pageName={"Businesses Detail"}
            status={{ data: data, state: state }}
            handleStatusApproved={handleStatusApproved}
            handleStatusDisapproved={handleStatusDisapproved}
            buttonProps={data?.is_approved ? true : false}
            isActive={isActive}
            onChange={handleStatus}
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          background: Colors.dark3,
          p: 2
        }}
      >
        <Box
          component={"form"}
          onSubmit={handleSubmit(updateData)}
          sx={{
            background: Colors.dark1,
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            borderRadius: "20px"
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              p: "16px 24px 0px 24px",
              position: "relative",
            }}
          >
            <PageDot top={"23px"} left={0} />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2
              }}
            >
              <UserIcon />
              <Typography
                sx={{
                  color: Colors.white,
                  fontWeight: 600,
                  fontSize: "18px"
                }}
              >
                Business Information
              </Typography>
              <Tooltip title="Edit">
                <EditButton
                  onClick={handleClick}
                  isEdit={isEdit}
                />
              </Tooltip>
              {isEdit && (
                <SecondaryButton
                  type={"submit"}
                  title={"Update"}
                />
              )}
            </Box>
          </Box>
          <Grid
            container
            sx={{
              borderTop: `2px solid ${Colors.dark2}`
            }}
          >
            <Grid item md={3.75}
              sx={{
                borderRight: `2px solid ${Colors.dark2}`,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                  p: 3
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    ".overlay": {
                      opacity: isEdit ? 1 : 0, // Apply hover effect only when isEdit is true
                    },
                  }}
                >
                  <CardMedia
                    component={"img"}
                    // src={Images.businessLogo}
                    src={baseUrl + updatedImage}
                    sx={{
                      width: "100%",
                      objectFit: "cover",
                      height: "250px",
                      borderRadius: "15px"
                    }}
                  />
                  <Box
                    className="overlay"
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      borderRadius: "15px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      opacity: 0, // Default to hidden
                      transition: "opacity 0.3s ease",
                    }}
                  >
                    {isEdit && (
                      <label>
                        <input
                          ref={(input) => (inputRef.current = input)}
                          type="file"
                          accept="image/*"
                          style={{ display: "none" }} // Hide the input
                          onChange={(e) => handleFileChange(e.target.files[0])}
                        />
                        <IconButton
                          onClick={() => inputRef.current.click()}
                          sx={{
                            border: `1px solid ${Colors.secondary}`
                          }}
                        >
                          <CloudUpload sx={{ color: Colors.secondary }} />
                        </IconButton>
                      </label>
                    )}
                  </Box>
                </Box>
                <Box
                  sx={{
                    borderBottom: `1px solid ${Colors.dark2}`,
                  }}
                >
                  <Box
                    sx={{
                      p: 1
                    }}
                  >
                    <Typography
                      sx={{
                        color: Colors.dark2,
                        fontSize: "10px"
                      }}
                    >
                      Name
                    </Typography>
                    {isEdit ? (
                      <EditField
                        register={register("name")}
                      />
                    ) : (
                      <Typography
                        variant='body2'
                        sx={{
                          color: Colors.white
                        }}
                      >
                        {data?.company_name}
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Box
                  sx={{
                    borderBottom: `1px solid ${Colors.dark2}`,
                  }}
                >
                  <Box
                    sx={{
                      p: 1
                    }}
                  >
                    <Typography
                      sx={{
                        color: Colors.dark2,
                        fontSize: "10px"
                      }}
                    >
                      Email
                    </Typography>
                    <Typography
                      variant='body2'
                      sx={{
                        color: Colors.white
                      }}
                    >
                      {data?.email}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    borderBottom: `1px solid ${Colors.dark2}`,
                  }}
                >
                  <Box
                    sx={{
                      p: 1
                    }}
                  >
                    <Typography
                      sx={{
                        color: Colors.dark2,
                        fontSize: "10px"
                      }}
                    >
                      Phone Number
                    </Typography>
                    <Typography
                      variant='body2'
                      sx={{
                        color: Colors.white
                      }}
                    >
                      {data?.phone}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item md={4.5}
              sx={{
                borderRight: `2px solid ${Colors.dark2}`,
              }}
            >
              <Grid
                container
                columnSpacing={2}
                sx={{
                  p: 2,
                  rowGap: 2,
                  height: membershipData?.membershipRequest != null ? "300px" : "auto",
                  overflow: "auto"
                }}
              >
                {data?.establishment?.pictures?.map((item, i) => (
                  <Grid
                    key={i}
                    item
                    md={i === 0 ? 12 : 6}
                    sx={{ position: "relative" }}
                  >
                    <CardMedia
                      component={"img"}
                      src={baseUrl + item}
                      sx={{
                        width: "100%",
                        height: i === 0 ? "250px" : "150px",
                        objectFit: "cover",
                        borderRadius: "15px",
                      }}
                    />
                  </Grid>
                ))}

              </Grid>
              {membershipData?.membershipRequest != null && (
                <Grid container spacing={2} p={2}>
                  <Grid item md={12}>
                    <Typography
                      variant={"h6"}
                      sx={{
                        color: Colors.white,
                        fontFamily: "Nunito Sans"
                      }}
                    >
                      Membership:
                    </Typography>
                  </Grid>
                  <Grid item md={6}>
                    <CardMedia
                      alt={"screenshot"}
                      component={"img"}
                      src={baseUrl + membershipData?.membershipRequest.screenshot}
                      sx={{
                        width: "100%",
                        height: "150px",
                        objectFit: "cover",
                        borderRadius: "15px"
                      }}
                      onClick={() => {
                        setOpen(true);
                        setSrc(membershipData);
                      }}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <Grid container spacing={2}>
                      {membershipData?.membershipRequest?.status == "pending" ||
                        membershipData?.membershipRequest?.status == "expired" ? (
                        <Fragment>
                          <Grid item md={12}>
                            <PrimaryButton
                              fullWidth
                              loading={approveLoading}
                              title={"Approve"}
                              onClick={() => approveMembership()}
                            />
                          </Grid>
                          <Grid item md={12}>
                            <SecondaryButton
                              fullWidth
                              loading={declineLoading}
                              title={"Reject"}
                              onClick={() => declineMembership()}
                            />
                          </Grid>
                        </Fragment>
                      ) : membershipData?.membershipRequest?.status == "approved" ? (
                        <Grid item md={12}>
                          <PrimaryButton
                            fullWidth
                            disabled={true}
                            title={"Approved"}
                          />
                        </Grid>
                      ) : (
                        <Grid item md={12}>
                          <SecondaryButton
                            fullWidth
                            disabled={true}
                            title={"Rejected"}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid item md={3.75}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                  p: 2,
                  height: "468px",
                  overflow: "auto"
                }}
              >
                <Typography
                  sx={{
                    color: Colors.white,
                    fontWeight: 600,
                    fontSize: "18px"
                  }}
                >
                  Brands
                </Typography>
                {brands?.map((item, ind) => (
                  <SecondaryCard
                    key={ind}
                    title={item.name}
                    onClick={() => navigate(`/brand-detail/${item.business_id}`, {
                      state: {
                        navigateFrom: state.navigateFrom,
                        data: item,
                        business: data
                      }
                    })}
                  />
                ))}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Fragment >
  )
  )
}

export default BusinessDetail;